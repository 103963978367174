import { User } from "@/variables/profile-page-types";

export function getReadyPlayerMeAvatar(user: User) {
  if (!user.avatar_link) return "https://d1ak9kdvdopy8s.cloudfront.net/items/default_avatar.svg";

  const avatarIdRe = /^https:\/\/api\.readyplayer\.me\/v1\/avatars\/([\w\d]+)\.glb$/;

  const avatarId = user.avatar_link.match(avatarIdRe)?.[1];

  if (!avatarId) return "https://d1ak9kdvdopy8s.cloudfront.net/items/default_avatar.svg";

  return `https://models.readyplayer.me/${avatarId}.png?size=256&pose=relaxed`;
}
