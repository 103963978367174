import { Module } from 'vuex';

type RootState = Record<string, unknown> // TODO: Replace it with real root state type

interface ModuleState {
  isVisible: boolean
  isLoading: boolean
}

export const CWP_MODULE_NAME = 'ConnectWalletPopupModule';

const ConnectWalletPopupModule: Module<ModuleState, RootState> = {
  namespaced: true,
  state: {
    isVisible: false,
    isLoading: false,
  },
  mutations: {
    openPopup(state) {
      state.isVisible = true;
    },
    closePopup(state) {
      state.isVisible = false;
    },
    togglePopup(state) {
      state.isVisible = !state.isVisible;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },
  },
};

export default ConnectWalletPopupModule;
