import { CardItemInfo } from '../itemInterface';

export const landsSlider1: CardItemInfo[] = [
  {
    id: 6,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/asia32.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/asia32.gif',
    tag: 'Gold',
    special_tag: 'Gold Asia',
    maximum_supply: 5626,
    title: '32 x 32 area',
    value: 2,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 3,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/asia64.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/asia64.gif',
    tag: 'Platinum',
    special_tag: 'Platinum Asia',
    maximum_supply: 468,
    title: '64 x 64 area',
    value: 12,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 17,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/north128.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/north128.gif',
    tag: 'Diamond',
    special_tag: 'Diamond North',
    maximum_supply: 187,
    title: '128 x 128 area',
    value: 20,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 20,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/east16v2.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/east16v2.gif',
    tag: 'Silver',
    special_tag: 'Silver East',
    maximum_supply: 12469,
    title: '16 x 16 area',
    value: 1,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 12,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/farm64.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/farm64.gif',
    tag: 'Platinum',
    special_tag: 'Platinum Farm',
    maximum_supply: 625,
    title: '64 x 64 area',
    value: 12,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 14,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/forest32.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/forest32.gif',
    tag: 'Gold',
    special_tag: 'Gold Forest',
    maximum_supply: 5626,
    title: '32 x 32 area',
    value: 2,
    type: 'LAND',
    affinity: '',
  },
];

export const landsSlider2: CardItemInfo[] = [
  {
    id: 17,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/north128.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/north128.gif',
    tag: 'Diamond',
    special_tag: 'Diamond North',
    maximum_supply: 187,
    title: '128 x 128 areas',
    value: 20,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 3,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/asia64.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/asia64.gif',
    tag: 'Platinum',
    special_tag: 'Platinum Asia',
    maximum_supply: 468,
    title: '64 x 64 area',
    value: 12,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 18,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/farm128.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/farm128.gif',
    tag: 'Diamond',
    special_tag: 'Diamond Farm',
    maximum_supply: 250,
    title: '128 x 128 area',
    value: 20,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 23,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/east128.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/east128.gif',
    tag: 'Diamond',
    special_tag: 'Diamond East',
    maximum_supply: 187,
    title: '128 x 128 area',
    value: 20,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 10,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/farm16.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/farm16.gif',
    tag: 'Silver',
    special_tag: 'Silver Farm',
    maximum_supply: 16625,
    title: '16 x 16 area',
    value: 1,
    type: 'LAND',
    affinity: '',
  },
  {
    id: 11,
    image: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/farm32.png',
    gif: 'https://d1ak9kdvdopy8s.cloudfront.net/items/lands/farm32.gif',
    tag: 'Gold',
    special_tag: 'Gold Farm',
    maximum_supply: 7500,
    title: '32 x 32 area',
    value: 2,
    type: 'LAND',
    affinity: '',
  },
];
