import wretch from 'wretch';
import store from '@/store';

interface Data {
  available: boolean
}

export default async function checkWalletAvailability(wallet: string): Promise<boolean> {
  const api = `${process.env.VUE_APP_API_LOCATION}/api/v1/wallets/available/?address=${wallet}`;

  try {
    const data: Data = await wretch(api).auth(`Bearer ${store.getters.accessToken}`).get().json();
    return data.available;
  } catch (err) {
    throw new Error(JSON.stringify(err));
  }
}
