/* eslint-disable linebreak-style */
interface Swiper {
    slidesPerView?: number,
    spaceBetween?: number,
    slidesPerGroup?: number,
    loop?: boolean,
    speed?: number,
    autoplay?: unknown,
    scrollbar?: unknown,
    breakpoints: unknown,
}

export const swiperMarketPlace: Swiper = {
  loop: true,
  autoplay: false,
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
  },
  breakpoints: {
    1900: {
      slidesPerView: 5,
      spaceBetween: 150,
    },
    1300: {
      slidesPerView: 4,
      spaceBetween: 150,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 150,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 150,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  },
};

export const swiperDetailPage : Swiper = {
  slidesPerGroup: 1,
  loop: true,
  speed: 3000,
  autoplay: {
    delay: 1500,
    disableOnInteraction: false,
    reverseDirection: false,
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
  },
  breakpoints: {
    1900: {
      slidesPerView: 5,
      spaceBetween: 150,
    },
    1300: {
      slidesPerView: 4,
      spaceBetween: 150,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 150,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 150,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  },
};

export const swiperHomePageFirst : Swiper = {
  slidesPerView: 6,
  spaceBetween: 30,
  slidesPerGroup: 1,
  loop: true,
  speed: 3000,
  autoplay: {
    delay: 1500,
    disableOnInteraction: false,
    reverseDirection: false,
  },
  breakpoints: {
    1630: {
      slidesPerView: 6,
      spaceBetween: 30,
    },
    1400: {
      slidesPerView: 5,
      spaceBetween: 30,
    },
    1100: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    830: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    530: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
  },
};

export const swiperHomePageSecond : Swiper = {
  slidesPerView: 6,
  spaceBetween: 30,
  slidesPerGroup: 1,
  loop: true,
  speed: 3000,
  autoplay: {
    delay: 1500,
    disableOnInteraction: false,
    reverseDirection: true,
  },
  breakpoints: {
    1630: {
      slidesPerView: 6,
      spaceBetween: 30,
    },
    1400: {
      slidesPerView: 5,
      spaceBetween: 30,
    },
    1100: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    830: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    530: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
  },
};
