const requiredVars = [
  'VUE_APP_POLYGON_TCG_ERC20_CONTRACT_ADDRESS',
  'VUE_APP_ETH_TCG_ERC20_CONTRACT_ADDRESS',
  'VUE_APP_BSC_TCG_ERC20_CONTRACT_ADDRESS',
  'VUE_APP_POLYGON_BRIDGE_CONTRACT_ADDRESS',
  'VUE_APP_ETH_BRIDGE_CONTRACT_ADDRESS',
  'VUE_APP_BSC_BRIDGE_CONTRACT_ADDRESS',
  'VUE_APP_POLYGON_CHAIN_ID',
  'VUE_APP_ETH_CHAIN_ID',
  'VUE_APP_BSC_CHAIN_ID',
  'VUE_APP_POLYGON_RPC',
  'VUE_APP_ETH_RPC',
  'VUE_APP_BSC_RPC',
  'VUE_APP_TCG_ERC20_DECIMALS',
  'VUE_APP_POLYGON_BRIDGE_ID',
  'VUE_APP_ETH_BRIDGE_ID',
  'VUE_APP_BSC_BRIDGE_ID',
];

const missingVars = requiredVars.reduce<string[]>((acc, item) => {
  if (!process.env[item]) {
    return [...acc, item];
  }
  return acc;
}, []);

if (missingVars.length) {
  throw new Error(`env vars ${missingVars.join(', ')} not set`);
}

export const POLYGON_TCG_ERC20_CONTRACT_ADDRESS = process.env.VUE_APP_POLYGON_TCG_ERC20_CONTRACT_ADDRESS!;
export const ETH_TCG_ERC20_CONTRACT_ADDRESS = process.env.VUE_APP_ETH_TCG_ERC20_CONTRACT_ADDRESS!;
export const BSC_TCG_ERC20_CONTRACT_ADDRESS = process.env.VUE_APP_BSC_TCG_ERC20_CONTRACT_ADDRESS!;

export const POLYGON_BRIDGE_CONTRACT_ADDRESS = process.env.VUE_APP_POLYGON_BRIDGE_CONTRACT_ADDRESS!;
export const ETH_BRIDGE_CONTRACT_ADDRESS = process.env.VUE_APP_ETH_BRIDGE_CONTRACT_ADDRESS!;
export const BSC_BRIDGE_CONTRACT_ADDRESS = process.env.VUE_APP_BSC_BRIDGE_CONTRACT_ADDRESS!;

export const POLYGON_BRIDGE_ID = process.env.VUE_APP_POLYGON_BRIDGE_ID!;
export const ETH_BRIDGE_ID = process.env.VUE_APP_ETH_BRIDGE_ID!;
export const BSC_BRIDGE_ID = process.env.VUE_APP_BSC_BRIDGE_ID!;

export const POLYGON_CHAIN_ID = parseInt(process.env.VUE_APP_POLYGON_CHAIN_ID!);
export const ETH_CHAIN_ID = parseInt(process.env.VUE_APP_ETH_CHAIN_ID!);
export const BSC_CHAIN_ID = parseInt(process.env.VUE_APP_BSC_CHAIN_ID!);

export const POLYGON_RPC = process.env.VUE_APP_POLYGON_RPC!;
export const ETH_RPC = process.env.VUE_APP_ETH_RPC!;
export const BSC_RPC = process.env.VUE_APP_BSC_RPC!;

export const TCG_ERC20_DECIMALS = parseInt(process.env.VUE_APP_TCG_ERC20_DECIMALS!);
