import { Location } from 'vue-router';

export interface INav {
  route?: Location
  label: string,
  isNew?: boolean
  icon?: string,
  children?: INav[],
  onClick?: () => void
}

export const authProfile: INav = {
  label: '',
  children: [
    {
      route: {
        name: 'Settings',
      },
      label: 'Profile',
      icon: 'other/icons/profile-v2.png',
    },
    {
      route: {
        name: 'Assets',
        query: {
          nav: 'owned',
        },
      },
      label: 'Assets',
      icon: 'other/icons/assets-v2.png',
    },
  ],
};

export const notAuthProfile: INav = {
  label: '',
  children: [
    {
      route: {
        name: 'Login',
      },
      label: 'Login',
    },
    {
      route: {
        name: 'SignUp',
        query: {
          nav: 'owned',
        },
      },
      label: 'Sign Up',
    },
  ],
};

const navStructure: INav[] = [
  // {
  //   route: {
  //     name: 'Game',
  //   },
  //   label: 'Game',
  // },
  {
    route: {
      name: 'News',
    },
    label: 'News',
  },
  {
    label: 'Merch',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, no-restricted-globals
    onClick() { location.href = 'https://tcgworldmerch.com/'; },
  },
  // {
  //   route: {
  //     name: 'Events',
  //   },
  //   label: 'Events',
  // },
  {
    route: {
      name: 'MarketPlace',
    },
    label: 'Marketplace',
  },
  {
    route: {
      name: 'MysteryBox',
    },
    label: 'Mystery Box',
  },
  {
    route: {
      name: 'Map',
    },
    label: 'Map',
  },
  {
    route: {
      name: 'WebGL',
    },
    label: 'WebGL',
  },
  {
    label: 'Finance',
    children: [
      {
        route: {
          name: 'Staking',
        },
        label: 'Staking',
        icon: 'other/icons/refresh-cw-v2.png',
      },
      {
        route: {
          name: 'Presale',
        },
        label: 'Buy TCG2',
        icon: 'items/logo_mobile.svg',
      },
      {
        route: {
          name: 'Exchange',
        },
        label: 'Swap',
        icon: 'other/icons/swap.png',
      },
    ],
  },
  {
    label: 'More',
    children: [
      {
        route: {
          name: 'Whitepaper',
        },
        label: 'Whitepaper',
        icon: 'other/icons/whitepaper-v2.png',
      },
      {
        route: {
          name: 'DragonBible',
        },
        label: 'Dragon Bible',
        icon: 'other/icons/dragon-bible-v2.png',
      },
      {
        route: {
          name: 'AboutUs',
        },
        label: 'About Us',
        icon: 'other/icons/about-us-v2.png',
      },
      {
        route: {
          name: 'Partners',
        },
        label: 'Partners',
        icon: 'other/icons/partners-v2.png',
      },
      {
        route: {
          name: 'CertificateVerification',
        },
        label: 'Verify Admins',
        icon: 'other/icons/search-v2.png',
      },
    ],
  },
];

export {
  navStructure,
};
