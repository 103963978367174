var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticStyle:{"text-decoration":"none"}},[_c('b-card',{staticClass:"crd",class:(typeof _vm.item.tag !== 'undefined' ? _vm.item.tag.toLowerCase() : ''),style:({
        background: ("url(" + _vm.backgroundGif + ") center / cover no-repeat"),
      })},[_c('div',{staticClass:"crd__img-container"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.gifLoaded),expression:"gifLoaded"}],staticClass:"crd-mainimg",attrs:{"src":_vm.item.gif},on:{"load":function($event){return _vm.onGifLoaded()}}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.gifLoaded),expression:"!gifLoaded"}],staticClass:"crd-mainimg",attrs:{"src":_vm.item.image}})]),_c('div',{staticClass:"crd-taginf clearfix"},[(_vm.item.affinity)?_c('span',{class:{
                    'crd-tag': true,
                    label: true,
                    starship: _vm.item.affinity === 'Starship',
                    wsb: _vm.item.affinity === 'WSB',
                    greed: _vm.item.affinity === 'Greed',
                }},[_vm._v(" "+_vm._s(_vm.item.affinity)+" ")]):_vm._e(),_c('span',{staticClass:"crd-tag tag",domProps:{"innerHTML":_vm._s(_vm.isSpecialTag ? _vm.item.special_tag : _vm.item.tag)}})]),_c('div',{staticClass:"crd-title"},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.item.title)}})]),_c('div',{staticClass:"crd-bottom text-center"},[_c('img',{staticClass:"crd-bottom__background",attrs:{"src":_vm.bottomBackgroundGif}}),_c('div',{staticClass:"crd-bottom__inner"},[_c('b-col',{staticClass:"d-flex crd-bottom-sec1",attrs:{"align-self":"start"}},[(_vm.totalMinted.count > 0)?_c('span',{staticClass:"crd-count",domProps:{"innerHTML":_vm._s(_vm.formatNumber(_vm.totalMinted.count) + '/' + _vm.formatNumber(_vm.item.maximum_supply))}}):_c('span',{staticClass:"crd-count",domProps:{"innerHTML":_vm._s('0/' + _vm.formatNumber(_vm.item.maximum_supply))}})]),_c('b-col',{attrs:{"align-self":"center"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }