import { computed, ref } from '@vue/composition-api';
import { Location } from 'vue-router';
import { INav } from '@/shared/lib/consts/navStructure';
import router from '@/router';

export default function useActiveChildrenLink(item: INav) {
  const currentActiveRoute = ref<Location | null>(null);
  const hasActiveRoute = ref(false);

  const hasChildren = computed(() => item.children && item.children?.length);

  function isActiveInside() {
    if (!item.children) {
      return false;
    }

    return Boolean(item.children.find((item) => item.route?.name === currentActiveRoute.value?.name));
  }

  function update() {
    currentActiveRoute.value = router.currentRoute as Location;
    hasActiveRoute.value = isActiveInside();
  }

  return {
    hasActiveRoute,
    hasChildren,
    update,
  };
}
