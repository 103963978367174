/* eslint-disable */

import Vue from "vue";
import Vuex from "vuex";
import { Route } from "vue-router";
import { Data } from "@vue/composition-api";
import { MobileMenuModule } from '@/widgets/MobileMenu';
import { ConnectWalletPopupModule } from '@/widgets/ConnectWalletPopup';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: localStorage.getItem("access_token") || null,
    refreshToken: localStorage.getItem("refresh_token") || null,
    APIData: "",
    // @ts-ignore
    user:
      localStorage.getItem("userData") != "undefined"
        ? JSON.parse(localStorage.getItem("userData") as string)
        : null,
    // @ts-ignore
    activeWallet:
      localStorage.getItem("activeWallet") != "undefined"
        ? JSON.parse(localStorage.getItem("activeWallet") as string)
        : null,
    walletConnector: null,
    provider: null,
    currentChainId: null,
    imageToBind: null,
    invitePopupVisible: false,
    connectWalletPopupVisible: false,
    prevRoute: {} as Route,
    mobileMenuVisible: false,
  },
  mutations: {
    updateLocalStorage(state: any, { access, refresh }) {
      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);
      state.accessToken = access;
      state.refreshToken = refresh;
    },
    updateAccess(state: any, access: any) {
      state.accessToken = access;
      localStorage.setItem("access_token", access);
    },
    destroyToken(state: any) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
    },
    updateUser(state: any, data: any) {
      let wallets = [];
      if (state.user?.wallets.length) {
        wallets = state.user.wallets;
      }
      state.user = data;
      state.user.wallets = wallets;
      localStorage.removeItem("userData");
      localStorage.setItem("userData", JSON.stringify(state.user));
    },
    updateWallets(state: any, data: any) {
      if (state.user) {
        state.user.wallets = data;
        localStorage.setItem("userData", JSON.stringify(state.user));
      }
    },
    updateActiveWallet(state: any, data: any) {
      state.activeWallet = data;
      if (!data) {
        localStorage.removeItem("activeWallet");
      } else {
        localStorage.setItem("activeWallet", JSON.stringify(data));
      }
    },
    setWalletConnector(state: any, data: any) {
      state.walletConnector = data.connector;
    },
    destroyWalletConnector(state: any, data: any) {
      state.walletConnector = null;
    },
    updateProvider(state: any, data: any) {
      console.log("updateProvider");
      state.provider = data.provider;
    },
    destroyProvider(state: any) {
      console.log("destroyProvider");
      state.provider = null;
    },
    setImageToBind(state, image: File) {
      state.imageToBind = image;
    },
    setPrevRoute(state, data: Route) {
      state.prevRoute = data;
    },
    setInvitePopupVisibility(state: Data, payload: boolean) {
      state.invitePopupVisible = payload;
    },
    setconnectWalletPopupVisible(state: Data, payload: boolean) {
      state.connectWalletPopupVisible = payload;
    },
  },
  getters: {
    loggedIn(state: any) {
      return state.accessToken && state.user;
    },
    user(state: any) {
      return state.user;
    },
    refreshToken(state: any) {
      return state.refreshToken;
    },
    accessToken(state: any) {
      return state.accessToken;
    },
    wallets(state: any) {
      if (state.user) {
        return state.user.wallets;
      }

      return [];
    },
    activeWallet(state: any) {
      return state.activeWallet;
    },
    walletConnector(state: any) {
      return state.walletConnector;
    },
    provider(state: any) {
      return state.provider;
    },
    getPrevRoute(state: any) {
      return state.prevRoute;
    },
  },
  actions: {
    logoutUser(context: any) {
      if (context.getters.loggedIn) {
        return new Promise((resolve: any, reject: any) => {
          fetch(`${process.env.VUE_APP_API_LOCATION}/api/v1/logout/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${context.getters.accessToken}`,
            },
          })
            .then((response: any) => {
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("userData");
              localStorage.removeItem("activeWallet");
              context.commit("destroyToken");
              resolve(response);
            })
            .catch((err: any) => {
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("userData");
              localStorage.removeItem("activeWallet");
              context.commit("destroyToken");
              reject(err);
            });
        });
      }
    },
    loginWithWallet(context: any) {
      return new Promise(async (resolve, reject) => {
        // @ts-ignore
        const from = ethereum.selectedAddress;
        // @ts-ignore
        const msg = `Login with ${ethereum.selectedAddress}`;
        // @ts-ignore
        const sign = await ethereum
          .request({
            method: "personal_sign",
            params: [msg, from, "Example password"],
          })
          .then((result: any) => result);

        fetch(`${process.env.VUE_APP_API_LOCATION}/api/v1/auth/wallet/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            // @ts-ignore
            address: ethereum.selectedAddress,
            signature: sign,
          }),
        })
          .then(async (response: any) => {
            if (response.status === 200) {
              // @ts-ignore
              this.activeWallet = ethereum.selectedAddress;

              return response.json();
            }
            if (response.status === 400) {
              reject(400);
            }
          })
          .then((data: any) => {
            data = JSON.parse(data);
            context.commit("updateLocalStorage", { access: data.access, refresh: data.refresh });

            return new Promise(() => {
              // user will be identified from request
              fetch(`${process.env.VUE_APP_API_LOCATION}/api/v1/users/me/`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${data.access}`,
                },
              })
                .then((responseSecond: any) => responseSecond.json())
                .then((userData: any) => {
                  context.commit("updateUser", userData);
                  resolve(200);
                });
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    loginUser(context: any, usercredentials: any) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_API_LOCATION}/api/v1/auth/api-token/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: usercredentials.email,
            password: usercredentials.password,
          }),
        })
          .then((response: any) => response.json())
          .then(
            (data: any) =>
              new Promise(() => {
                fetch(`${process.env.VUE_APP_API_LOCATION}/api/v1/login/`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    login: usercredentials.email,
                    password: usercredentials.password,
                  }),
                })
                  .then((responseData: any) => {
                    if (responseData.status == 200) {
                      context.commit("updateLocalStorage", {
                        access: data.access,
                        refresh: data.refresh,
                      });
                      return new Promise(() => {
                        // user will be identified from request
                        fetch(`${process.env.VUE_APP_API_LOCATION}/api/v1/users/me/`, {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: `Bearer ${data.access}`,
                          },
                        })
                          .then((responseSecond: any) => responseSecond.json())
                          .then((userData: any) => {
                            context.commit("updateUser", userData);
                            resolve(responseData);
                          });
                      });
                    }
                    reject(401);
                  })
                  .catch((err: any) => {
                    reject(err);
                  });
              })
          )
          .catch((err: any) => {
            reject(err);
          });
      });
    },
    loadWallets(context: any) {
      return new Promise((resolve, reject) => {
        fetch(
          `${process.env.VUE_APP_API_LOCATION}/api/v1/users/${
            context.getters.user ? context.getters.user.id : 0
          }/wallets/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${context.getters.accessToken}`,
            },
          }
        )
          .then((response) => response.json())
          .then((result) => {
            context.commit("updateWallets", result);
          })
          .catch((error) => {
            context.commit("updateWallets", []);
          });
      });
    },
    changeMetamaskChain(context: any) {
      return new Promise(async (resolve, reject) => {
        try {
          // @ts-ignore
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${Number(process.env.VUE_APP_CHAIN_ID).toString(16)}` }],
          });
        } catch (error: any) {
          // @ts-ignore
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${Number(process.env.VUE_APP_CHAIN_ID).toString(16)}`,
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
                chainName: "Binance Smart Chain",
                nativeCurrency: { name: "BNB", decimals: 18, symbol: "BNB" },
                blockExplorerUrls: ["https://bscscan.com"],
              },
            ],
          });
        }
      });
    },
    setActiveWallet(context: any, data: any) {
      context.commit("updateActiveWallet", data);
    },
    updateUser(context: any, user: any) {
      context.commit("updateUser", user);
    },
    setWalletConnector(context: any, data: any) {
      context.commit("setWalletConnector", data);
    },
    destroyWalletConnector(context: any) {
      context.commit("destroyWalletConnector");
    },
    updateProvider(context: any, data: any) {
      context.commit("updateProvider", data);
    },
    destroyProvider(context: any) {
      context.commit("destroyProvider");
    },
  },
  modules: {
    MobileMenuModule,
    ConnectWalletPopupModule,
  },
});
