import wretch, { Wretch, WretchResponse } from "wretch";
import { useStore } from "@vueblocks/vue-use-vuex";
import { Store } from "vuex";
import router from "../router";

type SupportedHTTPMethods = "GET" | "POST" | "PUT";
type SupportedResponces = "text" | "json" | "blob";

let api = wretch(`${process.env.VUE_APP_API_LOCATION}/api/v1`, { mode: "cors" });

const resolveMethod = (
  method: SupportedHTTPMethods,
  query: Wretch,
  route: string,
  body?: Object
) => {
  const resolvers: Record<SupportedHTTPMethods, Function> = {
    GET: () => query.get(route),
    POST: () => query.url(route).post(body),
    PUT: () => query.url(route).put(body),
  };

  return resolvers[method];
};

const resolveResponce = async <T>(
  data: WretchResponse,
  type: SupportedResponces
) => {
  const resolvers: Record<SupportedResponces, Function> = {
    text: () => data.text(),
    json: () => data.json(),
    blob: () => data.blob()
  }

  const res = (resolvers[type] || resolvers.text)();
  console.log(res)
  return res as T;
};

export const authorizedRequest = async <T>(
  endpoint: string,
  options: {
    method: SupportedHTTPMethods;
    body?: Object | FormData;

    headerOverrides?: Record<string, string>;

    // Optionally pass store instance for old pages
    store?: Store<any>;
    expectEmptyResponce?: boolean;

    convertAs?: SupportedResponces
  }
): Promise<T> => {
  const store = options.store || useStore();

  api = api.headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',

    ...options.headerOverrides,
  });

  const query = api.auth(`Bearer ${store.getters.accessToken}`);
  const route = `${endpoint}`;

  const res = resolveMethod(options.method, query, route, options.body)();

  console.log("authorizedRequest", route);
  const data = await res.unauthorized(async (e, req) => {
    const { access: accessToken } = (await api
      .post(
        {
          refresh: store.getters.refreshToken,
        },
        "/auth/api-token-refresh/"
      )
      .unauthorized((e) => {
        store.commit("destroyToken");

        router.push({
            path: '/login'
        });
      })
      .json()) as { access: string };

    store.commit("updateAccess", accessToken);

    const retry = req.auth(`Bearer ${store.getters.accessToken}`);

    return resolveMethod(options.method, retry, "", options.body)();
  }).res();

  console.log('ee12')

  return resolveResponce<T>(
    data,
    options.expectEmptyResponce ? 'text' :
      (options.convertAs || 'json')
  );
};
