import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

// Always loaded on initial render
// Other views are lazy loading inside routes
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue'),
  },
  // {
  //   path: "/dragonClaim",
  //   name: "DragonClaim",
  //   component: () => import("../views/DragonClaim.vue"),
  // },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "termsAndConditions" */ '../views/TermsAndConditions.vue'),
  },
  {
    path: '/staking-terms-and-conditions',
    name: 'StakingTermsAndConditions',
    component: () => import(
      /* webpackChunkName: "stakingTermsAndConditions" */ '../views/StakingTermsAndConditions.vue'
    ),
  },
  {
    path: '/swap',
    name: 'Exchange',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/exchange/Exchange.vue'),
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
  },
  // {
  //   path: "/game",
  //   name: "Game",
  //   component: () => import(/* webpackChunkName: "game" */ "../views/Game.vue"),
  // },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import(/* webpackChunkName: "partners" */ '../views/partners/Partners.vue'),
  },
  {
    path: '/partners/:id',
    name: 'PartnerItem',
    component: () => import(/* webpackChunkName: "partnerItem" */ '../views/partners/PartnerItem.vue'),
  },
  {
    path: '/presale/4ebd020883285d698c44ec50939c0967',
    name: 'PresaleWithParams',
    component: () => import(/* webpackChunkName: "presaleWithParams" */ '../views/Presale.vue'),
  },
  {
    path: '/presale',
    name: 'Presale',
    component: () => import(/* webpackChunkName: "presale" */ '../views/Presale.vue'),
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
  },
  // {
  //   path: '/events',
  //   name: 'Events',
  //   component: () => import(/* webpackChunkName: "events" */ '../views/Events'),
  // },
  // {
  //   path: '/events/:event',
  //   name: 'EventSlug',
  //   component: () => import(/* webpackChunkName: "eventsSlug" */ '../views/EventSlug'),
  // },
  {
    path: '/webgl',
    name: 'WebGL',
    component: () => import(/* webpackChunkName: "news" */ '../views/WebGL.vue'),
  },
  // {
  //   path: "/sale",
  //   name: "Sale",
  //   component: () => import(/* webpackChunkName: "game" */ "../views/Sale.vue"),
  // },
  // {
  //   path: "/collections/sale-item/:id",
  //   name: "SaleItemDetail",
  //   component: () => import(/* webpackChunkName: "game" */ "../views/SaleItemDetail.vue"),
  // },
  {
    path: '/verify',
    name: 'CertificateVerification',
    component: () => import(/* webpackChunkName: "verify" */ '../views/CertificateVerification.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signUp" */ '../views/SignUp.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/signuptx',
    name: 'SignUpTX',
    component: () => import(/* webpackChunkName: "singUpTx" */ '../views/SignUpTX.vue'),
  },
  {
    path: '/verify-registration',
    name: 'EmailVerification',
    component: () => import(/* webpackChunkName: "emailVerification" */ '../views/EmailVerification.vue'),
  },
  {
    path: '/reset-password',
    name: 'PasswordResetVerification',
    component: () => import(
      /* webpackChunkName: "passwordResetVerification" */ '../views/PasswordResetVerification.vue'
    ),
  },
  {
    path: '/reset-password-email',
    name: 'PasswordResetEmail',
    component: () => import(/* webpackChunkName: "passwordResetEmail" */ '../views/PasswordResetEmail.vue'),
  },
  {
    path: '/marketplace',
    name: 'MarketPlace',
    component: () => import(/* webpackChunkName: "marketplace" */ '../views/MarketPlace.vue'),
  },
  {
    path: '/staking',
    name: 'Staking',
    component: () => import(/* webpackChunkName: "staking" */ '../views/Staking.vue'),
  },
  {
    path: '/asia',
    name: 'Asia',
    component: () => import(/* webpackChunkName: "asia" */ '../views/Asia.vue'),
  },
  {
    path: '/east',
    name: 'East',
    component: () => import(/* webpackChunkName: "east" */ '../views/East.vue'),
  },
  {
    path: '/north',
    name: 'North',
    component: () => import(/* webpackChunkName: "north" */ '../views/North.vue'),
  },
  {
    path: '/forest',
    name: 'Forest',
    component: () => import(/* webpackChunkName: "forest" */ '../views/Forest.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Profile.vue'),
    meta: {
      requiresLogin: true,
    },
    children: [
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue'),
      },
      {
        path: 'assets',
        name: 'Assets',
        component: () => import(/* webpackChunkName: "assets" */ '../views/profile/Assets.vue'),
      },
    ],
  },
  {
    path: '/collections/items/:id',
    name: 'ItemDetail',
    component: () => import(/* webpackChunkName: "itemDetail" */ '../views/ItemDetail.vue'),
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "aboutUs" */ '../views/AboutUs.vue'),
  },
  {
    path: '/mystery-box',
    name: 'MysteryBox',
    component: () => import(/* webpackChunkName: "mysteryBox" */ '../views/MysteryBox.vue'),
  },
  {
    path: '/whitepaper',
    name: 'Whitepaper',
    component: () => import(/* webpackChunkName: "whitepaper" */ '../views/Whitepaper.vue'),
  },
  {
    path: '/dragon-bible',
    name: 'DragonBible',
    component: () => import(/* webpackChunkName: "dragonBible" */ '../views/DragonBible.vue'),
  },
  // fallback
  {
    path: '/unsubscribe',
    name: 'UnsubscribeEmails',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/UnsubscribeEmails.vue'),
  },
  {
    path: '/service-agreement',
    name: 'ServiceAgreement',
    component: () => import(/* webpackChunkName: "serviceAgreement" */ '../views/ServiceAgreement.vue'),
  },
  {
    path: ':notFound(.*)',
    component: () => import(/* webpackChunkName: "whitepaper" */ '../views/404Page.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.meta) {
      return savedPosition;
    }

    if (to.meta.scrollToTop) {
      return { x: 0, y: 0 };
    }
    return savedPosition || { x: 0, y: 0 };
  },
});

export default router;
