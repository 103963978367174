var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['nav-item', _vm.className],on:{"click":function($event){$event.preventDefault();return _vm.item.onClick.apply(null, arguments)}}},[(_vm.hasChildren)?_c('WithDropdown',{scopedSlots:_vm._u([{key:"placeholder",fn:function(ref){
var dropdownVisible = ref.dropdownVisible;
return [_c('div',{staticClass:"dropdown-placeholder"},[_c('span',{class:{
            'dropdown-label': true,
            active: _vm.hasActiveRoute && !_vm.item.onClick
          }},[_vm._v(" "+_vm._s(_vm.item.label)+" ")]),_c('md-icon',{class:{
          'md-size-1x': true,
          'dropdown-icon': true,
          'active': dropdownVisible,
        }},[_vm._v(" expand_more ")])],1)]}},{key:"dropdown",fn:function(){return _vm._l((_vm.item.children),function(link){return _c('NavMenuLink',{key:link.label,attrs:{"link":link,"class-name":"dropdown-item"}})})},proxy:true}],null,false,1778270563)}):_c('NavMenuLink',{attrs:{"link":_vm.item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }