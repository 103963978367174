import BigNumber from 'bignumber.js';
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import vuescroll from 'vuescroll';
import PortalVue from 'portal-vue';
import Meta from 'vue-meta';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Notifications from 'vue-notification';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
// @ts-ignore
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// @ts-ignore
import AOS from 'aos';
import VueAwesomeCountdown from 'vue-awesome-countdown';
import VueMaterial from 'vue-material';
import VueSplide from '@splidejs/vue-splide';
import VTooltip from 'v-tooltip';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
import App from './App.vue';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/custom.css';
import './assets/scss/global.scss';
import 'aos/dist/aos.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

BigNumber.set({ EXPONENTIAL_AT: 50 });

if (typeof window !== 'undefined') {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
}

const IsMobilePlugin = {
  // @ts-ignore
  install(Vue, options) {
    Vue.prototype.isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    Vue.prototype.formatNumber = (value: number): string | undefined => {
      const parsedValue = Number(value);
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(parsedValue)) {
        const parts = value.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
      }
    };
  },
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://2ddde8678081491393f5794353018032@o1020909.ingest.sentry.io/5986524',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['dev', 'tcg.world', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    enabled: false
  });
}

Vue.use(VueMaterial);
Vue.use(VTooltip, {
  defaultHtml: false,
});
Vue.use(PortalVue);
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});
Vue.use(VueAwesomeCountdown, 'countdown');
Vue.use(Notifications);
Vue.component('Notifications', Notifications);
Vue.use(IsMobilePlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// @ts-ignore
Vue.use(VueSplide);
Vue.use(VueCompositionAPI);
Vue.use(VueAwesomeCountdown);
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: 'native',
    },
    scrollPanel: {
      scrollingX: false,
      scrollingY: true,
    },
    rail: {
      size: '8px',
      gutterOfEnds: '5px',
      gutterOfSide: '5px',
    },
    bar: {
      background: '#c1c1c1',
      size: '4px',
    },
  },
  name: 'CustomScroll',
});
Vue.component('CustomScroll', vuescroll);

Vue.config.productionTip = false;

router.beforeEach((to: any, from: any, next: any) => {
  if (to.matched.some((record: any) => record.meta.requiresLogin)) {
    if (!store.getters.loggedIn) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else if (to.matched.some((record: any) => record.meta.requiresGuest)) {
    if (store.getters.loggedIn) {
      next({ name: 'UserDetail', params: { id: store.getters.user.id } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export const eventBus = new Vue(); // added line

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted() {
    AOS.init({
      once: true,
    });
  },
}).$mount('#app');
