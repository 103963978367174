const apiBasePath = process.env.VUE_APP_API_LOCATION;

export async function retrieveActiveWallets({ accessToken }: { accessToken: string }): Promise<string> {
  const res = await fetch(`${apiBasePath}/api/v1/wallets/active_wallet/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return (await res.json()).address;
}
