import { Module } from 'vuex';

type RootState = Record<string, unknown> // TODO: Replace it with real root state type

interface ModuleState {
  isVisible: boolean
}

export const MOBILE_MENU_MODULE_NAME = 'MobileMenuModule';

const MobileMenuModule: Module<ModuleState, RootState> = {
  namespaced: true,
  state: {
    isVisible: false,
  },
  mutations: {
    openMenu(state) {
      state.isVisible = true;
    },
    closeMenu(state) {
      state.isVisible = false;
    },
    toggleMenu(state) {
      state.isVisible = !state.isVisible;
    },
  },
};

export default MobileMenuModule;
