import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from "@walletconnect/ethereum-provider/dist/index.es";
import detectEthereumProvider from "@metamask/detect-provider";
import { MetaKeep } from "metakeep";
/* eslint-disable */

import {
  BigNumber,
  Contract,
  PayableOverrides,
  providers,
  ContractTransaction,
  utils,
} from "ethers";
import { parseUnits } from "ethers/lib/utils";
import BigNumberJS from "bignumber.js";
import { Web3Provider } from "@ethersproject/providers";
import { ZoraFeeSettings__factory } from "@/generated/contracts";
import {
  BSC_CHAIN_ID,
  BSC_RPC,
  ETH_CHAIN_ID,
  ETH_RPC,
  POLYGON_CHAIN_ID,
  POLYGON_RPC,
} from "@/variables/envVars";
import Web3 from "web3";

interface Wallet {
  accountAddress: string;
  chainId: number;
  type: string;
}

export const getMetamaskEthereum = async (): Promise<any> => {
  // @ts-ignore
  return window.ethereum.providers?.find((provider: any) => provider.isMetaMask) || window.ethereum;
};

export const isMetamaskConnected = async () => {
  const provider = await getMetamaskEthereum();

  return provider.isMetamask;
};

export const getBlockchainProvider = async (
  walletProvider: string,
  walletConnectProvider?: WalletConnectProvider
) =>
  new providers.Web3Provider(
    walletProvider === "metamask" ? await getMetamaskEthereum() : walletConnectProvider,
    "any"
  );

export const connectWalletByMetamask = async (
  onAccountsChanged?: (accountAddresses: Array<string>) => void,
  onChainChange?: (id: number) => void
): Promise<providers.ExternalProvider> => {
  const ethereum = await getMetamaskEthereum();

  if (ethereum) {
    ethereum.removeAllListeners();
  }

  console.log("waiting for accounts");

  console.log("ethereum provider: ", ethereum);

  // @ts-ignore
  const accounts = await ethereum.request({ method: "eth_requestAccounts" });

  if (!ethereum) {
    throw new Error("Metamask extension is not available");
  }

  console.log("settings handlers");

  ethereum.on(
    "accountsChanged",
    (accounts: Array<string>) => onAccountsChanged && onAccountsChanged(accounts)
  );
  ethereum.on(
    "chainChanged",
    (chainIdHex: string) => onChainChange && onChainChange(parseInt(chainIdHex, 16))
  );

  console.log("handlers set, returning ethereum");

  return ethereum;
};

export const getWalletConnectProvider = async () => {
  const provider = (await EthereumProvider.init({
    projectId: "3cb91cba8e34148aa429a34ff1e8c37a",

    rpcMap: {
      97: "https://api-eu1.tatum.io/v3/bsc/web3/8ac074a3-0373-4e80-a904-88fc23f468e5",
      56: "https://bsc-dataseed.binance.org/",
      1: "https://main-light.eth.linkpool.io/",
      4: "https://rinkeby-light.eth.linkpool.io/",
      [ETH_CHAIN_ID]: ETH_RPC,
      [POLYGON_CHAIN_ID]: POLYGON_RPC,
      [BSC_CHAIN_ID]: BSC_RPC,
    },

    chains: [ETH_CHAIN_ID],

    optionalChains: [BSC_CHAIN_ID],

    showQrModal: true,
    qrModalOptions: {
      themeVariables: {
        "--wcm-z-index": 99999,
      },

      enableExplorer: true,
    },
  })) as EthereumProvider;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return provider;
};

export const connectWallet = async (
  walletProvider: string,
  onDisconnect: () => void,
  onAccountsChange: (accountAddresses: string[]) => void,
  onChainChange?: (chainId: number) => void
): Promise<WalletConnectProvider | providers.ExternalProvider | providers.Web3Provider | any> => {
  switch (walletProvider) {
    case "metamask":
      return connectWalletByMetamask(onAccountsChange, onChainChange);

    case "walletconnect":
      return connectWalletByWalletConnect(onDisconnect, onAccountsChange, onChainChange);

    case "metakeep":
      return connectWalletByMetakeep();
  }

  throw new Error("Unknown wallet provider");
};

export const connectWalletByMetakeep = async () => {
  const sdk = new MetaKeep({
    appId: "7ef93d95-8bde-4743-bcef-fbcd3177eb20",
    chainId: 97,

    rpcNodeUrls: {
      // @ts-ignore
      97: "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
      56: "https://bsc-dataseed.binance.org/",
      1: "https://main-light.eth.linkpool.io/",
      4: "https://rinkeby-light.eth.linkpool.io/",
      [ETH_CHAIN_ID]: ETH_RPC,
      [POLYGON_CHAIN_ID]: POLYGON_RPC,
    }
  });


  const provider = await sdk.ethereum;
  
  console.log(provider);

  await provider.enable();

  const ethersProvider = new providers.Web3Provider(provider);

  console.log(ethersProvider, 'metakeep provider');

  return ethersProvider;
};

export const connectWalletByWalletConnect = async (
  onDisconnect: () => void,
  onAccountsChange: (accountAddresses: string[]) => void,
  onChainChange?: (chainId: number) => void
): Promise<WalletConnectProvider> => {
  let walletConnectProvider = await getWalletConnectProvider();
  try {
    const accounts = await walletConnectProvider.enable();
  } catch (err) {
    console.log(err);
    return walletConnectProvider;
  }

  walletConnectProvider.on("disconnect", onDisconnect);
  walletConnectProvider.on(
    "accountsChanged",
    (accounts: string[]) => onAccountsChange && onAccountsChange(accounts)
  );

  // For walletconnect v2
  if (onChainChange) {
    const onChainChangeV2 = (chainId: string) => onChainChange(+chainId);
    walletConnectProvider.on("chainChanged", onChainChangeV2);
  }

  function isIOS() {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  if (walletConnectProvider instanceof WalletConnectProvider) {
    if (walletConnectProvider.signer.client.metadata.url === "https://trustwallet.com") {
      if (document.body.offsetWidth <= 768 && isIOS()) {
        // @ts-ignore
        window.IS_TRUST_WALLET_MOBILE = true;
      }
    }
  }

  return walletConnectProvider;
};

export const disconnectWallet = async (walletConnectProvider: EthereumProvider): Promise<void> => {
  const provider = walletConnectProvider;

  await provider.disconnect();
};

export const isWalletConnectConnected = async () => {
  const WalletConnectProvider = await getWalletConnectProvider();

  return WalletConnectProvider.connected;
};

export const sleep = async (timeout: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

const gasOracle = "https://api.bscscan.com/api?module=gastracker&action=gasoracle";

type GasStationResponse = {
  status: number;
  message: string;
  result: {
    LastBlock: string;
    SafeGasPrice: string;
    ProposeGasPrice: string;
    FastGasPrice: string;
    UsdPrice: string;
  };
};

async function fetchWithTimeout(resource: RequestInfo, options?: RequestInit) {
  const timeout = 3000;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, { ...options, signal: controller.signal });
  clearTimeout(id);

  return response;
}

export const getEstimateGasPrice = async (provider: providers.Web3Provider): Promise<BigNumber> => {
  let gasPrice = await provider.getGasPrice();
  try {
    const response = (await (await fetchWithTimeout(gasOracle)).json()) as GasStationResponse;
    gasPrice = parseUnits(`${Math.ceil(Number(response.result.FastGasPrice))}`, "gwei");
  } catch (error) {
    // silence is golden
    console.error(error);
  } finally {
    const price = gasPrice;

    return price;
    // if (gasPrice.lt(parseUnits('20', 'gwei'))) {
    //     return parseUnits('20', 'gwei');
    // } else {
    //     return price;
    // }
  }
};

export async function runContractFunctionWithGas<
  C extends Contract,
  N extends keyof C["functions"]
>({
  contract,
  functionName,
  args,
  value,
}: {
  contract: C;
  functionName: N;
  args: Parameters<C["functions"][N]>;
  value?: BigNumber;
  //@ts-ignore
}): Promise<ContractTransaction> {
  // we're assuming here, since we're not using any other provider than Web3Provider anywhere
  const provider = contract.provider as providers.Web3Provider;

  const gasPrice = await getEstimateGasPrice(provider);
  await sleep(100);
  functionName;
  const contractOverrides: PayableOverrides = {
    gasPrice,
    // @ts-ignore
    gasLimit: await contract.estimateGas[functionName](...args, { gasPrice, value }),
    value,
  };

  return await contract[functionName](...args, contractOverrides);
}

export function getPriceSummary(
  price: number | string,
  feePercent: number | string,
  minusFee = false,
  reversePercent = false
): {
  totalAmountSC: BigNumberJS;
  totalAmount: string;
  priceFormatted: string;
  feeAmount: string;
  feeAmountNumber: number;
  feeAmountBigNumber: BigNumberJS;
} {
  const priceBN = new BigNumberJS(price);
  const feeAmount = new BigNumberJS(price)
    .div(100 + (reversePercent ? -feePercent : 0))
    .multipliedBy(feePercent);
  const totalAmount = minusFee ? priceBN.minus(feeAmount) : priceBN.plus(feeAmount);

  return {
    totalAmountSC: totalAmount,
    totalAmount: formatBigNumberUI(totalAmount),
    priceFormatted: formatBigNumberUI(priceBN),
    feeAmount: formatBigNumberUI(feeAmount),
    feeAmountNumber: Number(feeAmount.toString()),
    feeAmountBigNumber: feeAmount,
  };
}

const zoraFeeSettingsAddress = process.env.VUE_APP_TCG_ZORA_FEE_SETTINGS_CONTRACT_ADDRESS || "";
export const decToHex = (val: number): string => `0x${val.toString(16)}`;
export async function getFeePercent(
  provider: Web3Provider,
  address: string
): Promise<number | undefined> {
  if ((await provider.getNetwork()).chainId !== Number(process.env.VUE_APP_CHAIN_ID)) {
    if (provider.provider?.request) {
      await provider.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: decToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
      });
    }
  }
  if (provider.provider) {
    const feeSettings = ZoraFeeSettings__factory.connect(
      zoraFeeSettingsAddress,
      provider.getSigner()
    );
    try {
      const res = await feeSettings.functions.moduleFeeSetting(address);
      const percentage = res.feeBps / 100;

      return percentage;
    } catch (e) {
      console.log(e);
    }
  }
}

export function formatBigNumberUI(number: BigNumberJS, decimals = 2): string {
  const _decimals = Math.min(Math.max(decimals, number.decimalPlaces()!), 5);

  return number.toFormat(_decimals, { decimalSeparator: ".", groupSeparator: " ", groupSize: 3 });
}

export function stringToBytes32(text: string) {
  let result = utils.toUtf8Bytes(text);
  // if (result.length > 32) { throw new Error('String too long') }
  let result2 = utils.hexlify(result);
  while (result2.length < 66) {
    result2 += "0";
  }
  if (result2.length !== 66) {
    throw new Error("invalid web3 implicit bytes32");
  }
  return result2;
}
