import { ref, Ref, readonly, DeepReadonly, watchEffect, computed } from '@vue/composition-api';
import SID, { getSidAddress } from '@siddomains/sidjs';
import Web3 from 'web3';
import store from '../store';

interface SID {
  domain: DeepReadonly<Ref<Domain | null>>
  loading: DeepReadonly<Ref<boolean>>
}

interface Domain {
  name: string
}

interface Wallet {
  accountAddress: string
  chainId: number
  type: string
}

const cachedDomains: Record<string, Domain> = {};

export default function useSID(): SID {
  const domain = ref<Domain | null>(null);
  const loading = ref(false);

  const wallet = computed<Wallet>(() => {
    return store.getters.activeWallet || {};
  });

  watchEffect(async () => {
    try {
      loading.value = true;

      const provider = new Web3.providers.HttpProvider('https://bsc-dataseed1.defibit.io/');
      const sid = new SID({ provider, sidAddress: getSidAddress('56') });

      const address = wallet.value?.accountAddress || '';

      if (cachedDomains[address]) {
        domain.value = cachedDomains[address];
        return;
      }

      const domainResult = await sid.getName(address);
    
      domain.value = domainResult;
      cachedDomains[address] = domainResult;
    } finally {
      loading.value = false;
    }
  });

  return {
    domain: readonly(domain),
    loading: readonly(loading),
  };
}
